import React, { useState, useEffect } from 'react';
import './App.css';
import gamesData from './games.json';
import descriptions from './descriptions.json'; // Import the descriptions JSON

function App() {
  const [selectedGame, setSelectedGame] = useState(gamesData[0]);
  const [tagline, setTagline] = useState('');

  useEffect(() => {
    setSelectedGame(gamesData[0]);
  }, []);

  useEffect(() => {
    // Select a random tagline from the descriptions
    const randomTagline = descriptions[Math.floor(Math.random() * descriptions.length)];
    setTagline(randomTagline);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src="/images/logo.png" alt="Vagabond Dog Logo" className="logo" />
        <p>{tagline}</p>
      </header>
      <section className="game-details">
        <div className="image-container">
          <img src={selectedGame.image} alt={selectedGame.name} className="selected-image" />
        </div>
        <div className="game-info">
          <img src={selectedGame.logo} alt={`${selectedGame.name} Logo`} className="game-logo" />
          <p className="game-description">{selectedGame.description}</p>
          <div className="platforms-container">
            <div className="platforms">
              {selectedGame.platforms.map(platform => (
                <a href={platform.link} key={platform.name}>
                  <img src={platform.icon} alt={platform.name} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="game-thumbnails">
        {gamesData.map(game => (
          <img
            key={game.name}
            src={game.image}
            alt={game.name}
            className="thumbnail"
            onClick={() => setSelectedGame(game)}
          />
        ))}
      </section>
      <footer>
        <a href="/privacy.html" className="privacy-policy">Privacy Policy</a>
      </footer>
    </div>
  );
}

export default App;
